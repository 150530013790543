h1 {
  margin-bottom: 24px;
}

.documents-page {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  height: 100%;

  .documents-page-title {
    font-size: 24px;
    font-weight: 500;
    padding: 24px 24px 0 24px;
    margin-bottom: 0;
  }

  .upload-section {
    padding: 24px;
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: space-between;
  }

  .upload-section-right {
    display: flex;
  }

  .well-done {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 400px;

    p {
      font-size: 24px;
      font-weight: 500;
    }
  }
}

.file-list-container {
  height: calc(100vh - 172px);
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #f0f0f0;
  }

  &::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #bfbfbf;
  }
}

.file-card {
  width: 100%;
  height: 140px;

  .ant-card-body {
    padding: 16px 8px;
  }

  &--active {
    border-color: #1890ff;
    background-color: #e6f7ff;
  }

  .ant-card-meta-description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 1.4em;
    max-height: 2.8em;
  }
}

.tabs-sidebar {
  .ant-tabs-content-holder {
    height: calc(100vh - 86px);
    overflow-y: auto;
  }
}

.file-overview-title {
  font-size: 18px;
  font-weight: 500;
  padding: 8px;
  margin-bottom: 0;
}
