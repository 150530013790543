.ant-tabs-tab .anticon {
  margin-right: 0;
  font-size: 18px;
}

.ant-tabs-content {
  height: 100%;
}

.ant-tabs-tabpane {
  height: 100%;
}

.tab-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .divider {
    margin-left: -26px;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .content {
    flex: 1;
    padding-right: 26px;
  }

  .footer {
    margin-left: -26px;
    display: flex;
    justify-content: center;
  }

  .property {
    font-weight: 500;
    margin-bottom: 8px;
  }
}

/** Contract Selector */

.contract-selector {
  & > .ant-select-selector {
    height: 90px !important;

    & > .ant-select-selection-search {
      display: flex;
      align-items: center;
    }

    & > .ant-select-selection-item {
      line-height: unset;
    }

    .ant-select-single .ant-select-selector .ant-select-selection-item {
      line-height: unset;
    }
  }

  &.ant-select-open {
    .ant-select-selector {
      & > .ant-select-selection-item {
        display: none;
      }
    }
  }
}
