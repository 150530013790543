.pdf-editor-view-bar {
  &__bar-center {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  &__bar-right {
    display: flex;
    gap: 8px;
    position: absolute;
    right: 0;
  }

  &__scale-label {
    padding: 0 8px;
  }
}
